import APP_CONST from '@/constants/AppConst';
import analyticsStore from '@/store/modules/analyticsStore';
import programListStore from "@/store/modules/programList";
import { Component, Mixins } from 'vue-property-decorator';
import ExportToCsv from '@/ui-components/exportToCSV/exportToCSV.vue';
import MultiSelectDropdown from '@/ui-components/multiSelectDropdown/MultiSelectDropdown.vue';
import LineChart from '@/chartcomponents/linecomponent/LineChart.vue';
import APP_UTILITIES from '@/utilities/commonFunctions';
import DeviceWidthCheckerMixin from '@/mixins/deviceWidthChecker.mixin';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import { ScreenText } from '@/lang/ScreenText';

@Component({
    components: {
      'multi-select-dropdown': MultiSelectDropdown,
      "export-to-csv":ExportToCsv,
      "line-chart": LineChart,
      "bouncing-preloader": BouncingPreloaderComponent,
    }
  })

export default class ProfessionalLearningAnalyticsComponent extends Mixins(DeviceWidthCheckerMixin) {
  public coursesDropdown = APP_CONST.PROF_LEARNING_COURSES_CONFIG;
  public rolesDropdown = APP_CONST.PROF_LEARNING_ROLES_CONFIG;
  public yScaleLabel= 'Courses completed';
  private objScreenText: ScreenText = new ScreenText();
  public isTabletView:boolean = false;
  public programIds : number[] = [];
  public isLoaderVisible: boolean = false;
  public getProfLearningDataRes: any = {}
  public programAdminData:any = []
  public sessionAdminData:any = []
  public staffAdminData:any = []
  public programAdminDatalength:number = 0 
  public sessionAdminDatalength:number = 0 
  public staffAdminDatalength:number = 0 
  public courseSelectedIds:any = []

  get courses(){
    return analyticsStore.categoriesList
  }

  get roles(){
    return analyticsStore.rolesList
  }

  get selectedRoles(){
    return analyticsStore.selectedRoleIds
  }

  get selectedCourses(){
    return analyticsStore.selectedCategoryIds
  }
  
  get showLegends(){
    return ((this.selectedRoles && this.selectedRoles.length)|| this.selectedRoles && (this.selectedRoles.length< APP_CONST.ONE))
  }

  get legends(){
    return analyticsStore.learningLegends
  }

  get graphData(){
    return analyticsStore.prefLearningChartData
  }

  get lineChartStyle(){
    let graphDataLength:number = 0;
    if(this.isMobile){
      graphDataLength= (this.graphData && this.graphData.labels && this.graphData.labels.length) ? this.graphData.labels.length * (this.graphData.labels.length * 50 > 110  ? 110 :this.graphData.labels.length * 50) : 0;
      }else{
        graphDataLength= (this.graphData && this.graphData.labels && this.graphData.labels.length) ? this.graphData.labels.length * (this.graphData.labels.length * 10 > 110 ? 110:this.graphData.labels.length * 10) : 0;
    }
    let styles: any = {
        width: graphDataLength>document.documentElement.clientWidth?`${graphDataLength}px`:'100%',
        height: "400px",
        position: "relative"
    }
    return styles;
  }

  get emptyStateMessage(){
    let emptyStateMessage = APP_CONST.BLANK;
    if(!this.courses || !this.courses.length){
      emptyStateMessage = APP_CONST.AWAITING_COURSES;
  }
    else if(this.selectedCourses && !this.selectedCourses.length){
        emptyStateMessage = APP_CONST.NO_CATEGORY_SELECTED;
    }
    
    return emptyStateMessage
  }

  get bxAdminOrSuperAdmin(){
    let highestRole: any = Number(APP_UTILITIES.getCookie("highest_role"));
    return this.isMobilePortrait && [APP_CONST.ROLE_TYPE_SUPER_ADMIN, APP_CONST.ROLE_TYPE_BX_ADMIN].includes(highestRole)
  }

  get disableCsvButtonProfessionalLearningData(){
    return  analyticsStore.disableDownloadCsvProfessionalLearningData;
  }

  get coursesDisabled(){
    return this.courses && ((this.courses.length == APP_CONST.ZERO) || (this.courses.length == APP_CONST.ONE))
  }

  get completeProfLearningData(){
    return analyticsStore.getCompleteProfLearningData
  }

  get totalCoursesCompleted(){
    if(this.graphData && this.graphData.datasets && this.graphData.datasets.length){
      let sum:number = this.graphData.datasets.flatMap((x:any)=>x.data).flatMap((values:any)=>values.y).reduce((partialSum:number, value:number) => partialSum + value, 0);
      return sum;
    }
    else
      return '--'
  }

  get roleCounts(){
    return analyticsStore.rolesCountList
  }

  /**
   * Select roles function call 
   * 
   * @param role 
   */
  selectRoles(role:any){
    if(role && role.selectedValue != undefined){
      let selectedRoles:any = []
      let unSelectedRoles:any = []
      let roleIds:any = []
      if(role.dropdownList && role.dropdownList.length){
        role.dropdownList.forEach((roleItem:any)=>{
          if(roleItem.checked){
            selectedRoles.push(roleItem.id)
          } else {
            unSelectedRoles.push(roleItem.id)
          }
        })
        roleIds = (unSelectedRoles.length == role.dropdownList.length)?unSelectedRoles:selectedRoles
        analyticsStore.mutateSelectedRole(role.dropdownList)
        let programId:any= APP_UTILITIES.getCookie('programId');
        analyticsStore.locallyStoreProfLearningData({ programId: programId, categories: this.courses, roles: role.dropdownList })
      }
      analyticsStore.filterProfessionalLearningData({roles: role.dropdownList, courses: this.courses})
      this.getProfLearningDataResponse();
    }
  }

  /**
   * Select categories function call
   * 
   * @param category 
   */
   selectCourses(category:any){
    if(category && category.selectedValue != undefined){
      let selectedIds:any = []
      let unSelectedIds:any = []
      let courseIds:any = []
      if(category && category.dropdownList && category.dropdownList.length){
        category.dropdownList.forEach((roleItem:any)=>{
          if(roleItem.checked){
            selectedIds.push(roleItem.id)
          } else {
            unSelectedIds.push(roleItem.id)
          }
        })
        courseIds = (unSelectedIds.length == category.dropdownList.length)?unSelectedIds:selectedIds
        analyticsStore.mutateSelectedCategory(category.dropdownList)
        let programId:any= APP_UTILITIES.getCookie('programId');
        analyticsStore.locallyStoreProfLearningData({ programId: programId, categories: category.dropdownList, roles: this.roles })
        }
      if(unSelectedIds && category && category.dropdownList && (unSelectedIds.length == category.dropdownList.length)){
        analyticsStore.resetProfessionalLearningData()
      } else {
        analyticsStore.filterProfessionalLearningData({roles: this.roles, courses: category})
      }
      this.getProfLearningDataResponse();
    }
  }

  getProfLearningDataResponse(){
    this.programAdminData = []
    this.sessionAdminData = []
    this.staffAdminData = []
    let selectedCategory:any = []
    this.courses.forEach((item:any) => {
      if(item.checked){
        selectedCategory.push(item.id)
      }
    });
    this.courseSelectedIds = selectedCategory
    this.getProfLearningDataRes.dataSet && this.getProfLearningDataRes.dataSet.forEach((element:any) => {
      selectedCategory.forEach((selectVal:any) => {
        if(element.roleId == APP_CONST.ROLE_TYPE_PROGRAM_ADMIN){
          element.data.forEach((data:any) => {
            if(data.x == selectVal){
              if(data.y != null){
                data.userGuids.length && data.userGuids.forEach((guid:any) => {
                  if(!this.programAdminData.includes(guid)){
                    this.programAdminData.push(guid)
                  }
                });
              }
            }
          });
        }
        else if(element.roleId == APP_CONST.ROLE_TYPE_SESSION_ADMIN){
          element.data.forEach((data:any) => {
            if(data.x == selectVal){
              if(data.y != null){
                data.userGuids.length && data.userGuids.forEach((guid:any) => {
                  if(!this.sessionAdminData.includes(guid)){ 
                    this.sessionAdminData.push(guid)
                  }
                });
              }
            }
          });
        }
        else if(element.roleId == APP_CONST.ROLE_TYPE_STAFF){
          element.data.forEach((data:any) => {
            if(data.x == selectVal){
              if(data.y != null){
                data.userGuids.length && data.userGuids.forEach((guid:any) => {                  
                  if(!this.staffAdminData.includes(guid)){                   
                    this.staffAdminData.push(guid)
                  }
                });
              }
            }
          });
        }
      });
    });
    this.programAdminDatalength = this.programAdminData.length
    this.sessionAdminDatalength = this.sessionAdminData.length
    this.staffAdminDatalength = this.staffAdminData.length
  }

  getProfLearningData(){
    if(this.programIds && this.programIds.length){
      analyticsStore.getProfLearningData(this.programIds).then((response:any)=>{
        if(response.status == 200){
          this.getProfLearningDataRes = response.data
        }
        let programId:any= APP_UTILITIES.getCookie('programId');
        analyticsStore.getProfLearningPersistence({programId: programId})
        this.getProfLearningDataResponse()
        analyticsStore.filterProfessionalLearningData({roles: this.roles, courses: this.courses})
        this.isLoaderVisible = false;
      })
    }
  }

  get maxYAxisData() {
    let data: any = {};
    if (this.graphData && this.graphData.datasets && this.graphData.datasets.length) {
      this.graphData.datasets.forEach((element: any) => {
        if (element.data) {
          const y = element.data.map((ele: any) => {
            return ele.y;
          })
          element.maxY = Math.max(...y)
        }
      })
      const maxYY = this.graphData.datasets.map((element: any) => {
        return element.maxY;
      })
      let maxYValue = Math.max(...maxYY);
      let finalYValue:number = maxYValue
      if(maxYValue < 100){
        finalYValue = Math.round(maxYValue / 10) * 10
        return finalYValue > maxYValue ? finalYValue : finalYValue+10
      }else if(maxYValue < 1000){
        finalYValue = Math.round(maxYValue / 50) * 50
        return finalYValue > maxYValue ? finalYValue : finalYValue+50
      }else{
        finalYValue = Math.round(maxYValue / 500) * 500
        return finalYValue > maxYValue ? finalYValue : finalYValue+500
      }
      
    } else {
      return APP_CONST.HUNDRED
    }
  }

  mounted(){
    this.isLoaderVisible = true;
    this.isTabletView =APP_UTILITIES.tabletCheck();
    let storedAccountId:any = APP_UTILITIES.getCookie('user_role');

    
    let {id} = APP_UTILITIES.coreids();
    const payload = APP_CONST.ACCOUNT_ADMIN_PROGRAMS_LIST;
    payload.id = JSON.parse(storedAccountId) ? JSON.parse(storedAccountId)[0].accountId:0;
    payload.userId = id;
    payload.filterKey = 0;
    window.addEventListener("resize", APP_UTILITIES.debounce(this.isTablet));
    programListStore.updateProgramAndSiteList(payload).then((response:any)=> {
      let activeArray:any=programListStore.programListFilters;
      this.programIds = activeArray.map((x:any)=>x.programId);
      if(this.programIds && this.programIds.length && (this.programIds[0]!==undefined)){
        analyticsStore.getRoleWiseUserCount(this.programIds);
        this.getProfLearningData();
      }
    });
  }

  destroyed(){
    analyticsStore.resetProfessionalLearningData()
  }

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  isTablet(){
    this.isTabletView = APP_UTILITIES.tabletCheck()
  }

  professionalLearningCsvDownload(){
    if(this.programIds && this.programIds.length){
    analyticsStore.downloadCsvProfessionalLearning(this.programIds);
    }
  }
}